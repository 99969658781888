import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import LoadMapsApi from "../../components/maps/LoadMapsApi";
import MapWithMarkers from "../../components/maps/MapWithMarkers";
import { markerPositionsHI, mapCenterHI, markerPositionsSF, mapCenterSF, mapCenterESP, markerPositionsNZ, mapCenterNZ } from "../../components/maps/data";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>

      <LoadMapsApi />
      <MDXTag name="p" components={components}>{`This is part 1 of a 3-part series about using Google Maps and D3 to create an elevation graph that responds dynamically with user interaction. Here are all 3 posts:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "/posts/2019-02-11-draw-an-interactive-elevation-chart-with-d3-and-react"
          }}>{`Part 1: Google Maps`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "/posts/2019-02-16-draw-an-interactive-elevation-chart-with-d3-and-react"
          }}>{`Part 2: D3 for Data Visualization`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "/posts/2019-03-27-draw-an-interactive-elevation-chart-with-d3-and-react-pt-3"
          }}>{`Part 3: Creating User Interaction`}</MDXTag></MDXTag>
      </MDXTag>
      <MDXTag name="h2" components={components}>{`To DOM, or Not to (Virtual) DOM`}</MDXTag>
      <MDXTag name="p" components={components}>{`One of the first decisions we have to make when using D3 and React together is `}<MDXTag name="strong" components={components} parentName="p">{`who gets control of the DOM`}</MDXTag>{`. In this video `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.youtube.com/watch?v=zXBdNDnqV2Q"
        }}>{`Shirley Wu does a great job explaining`}</MDXTag>{` when each library should get control.`}</MDXTag>
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`Check out the project’s `}<MDXTag name="a" components={components} parentName="p" props={{
            "href": "https://github.com/Lambda-School-Labs/LabsPT1_Backwoods"
          }}>{`source code on GitHub`}</MDXTag>{`, or watch the 15-second demo below!`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`Shirley goes on to explain that it’s usually best to let React paint the DOM since it manages state and, presumably, the inputs to our chart will live on state.`}</MDXTag>
      <MDXTag name="p" components={components}>{`However in our case, because we have 100 elevation samples from Google Maps API to manage (each sample a nested object containing keys such as `}<MDXTag name="inlineCode" components={components} parentName="p">{`elevation`}</MDXTag>{` and `}<MDXTag name="inlineCode" components={components} parentName="p">{`location`}</MDXTag>{`, which is itself an object with `}<MDXTag name="inlineCode" components={components} parentName="p">{`latitude`}</MDXTag>{` and `}<MDXTag name="inlineCode" components={components} parentName="p">{`longitude`}</MDXTag>{` keys), it didn't make sense from a performance standpoint to give React control of the DOM.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Even if we were to use a PureComponent to avoid doing deep comparisons and minimize re-renders, we’re still managing references to 100 objects, and 100 shallow comparisons is still 100 comparisons.`}</MDXTag>
      <MDXTag name="p" components={components}>{`So we made the decision to store things like map markers on state only when absolutely necessary, and to never store our samples on state. For this reason, `}<MDXTag name="strong" components={components} parentName="p">{`we decided to let D3 control how the ElevationChart paints the DOM`}</MDXTag>{`.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`What We’ll Be Building`}</MDXTag>
      <MDXTag name="p" components={components}>{`Here’s the feature we’ll be building:`}</MDXTag>
      <iframe className="youtube-video" width="720" height="480" src="https://www.youtube.com/embed/9HXlmXwyuKk" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
      <MDXTag name="p" components={components}>{`We have 2 topics to cover: making Google Maps in React and data-visualizations with D3.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`First`}</MDXTag>{` we’ll cover 1) configuring and theming your map, 2) adding markers and drawing polylines, 3) dynamically adjusting the map’s “frame”, 4) using InfoWindows to show a marker’s elevation, and 5) getting a list of elevations along a pathline.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Second`}</MDXTag>{` we’ll build an ElevationChart using the list of elevation samples along the pathline. We’ll add interactive features and user hover events to dynamically render a DOM node on the map we created in the first section.`}</MDXTag>
      <MDXTag name="p" components={components}>{`I won’t cover how to get a Google Maps API key, and I assume you have a working knowledge of React lifecycle methods. We’ll be coding everything in JavaScript, but it would not be difficult to port this project to a different language if you prefer.`}</MDXTag>
      <MDXTag name="p" components={components}>{`A detailed API specs is available in the `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://developers.google.com/maps/documentation/"
        }}>{`Google Maps Documentation`}</MDXTag>{`.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`Making Maps with React`}</MDXTag>
      <MDXTag name="p" components={components}>{`This is what we need to do:`}</MDXTag>
      <MDXTag name="ol" components={components}>
        <MDXTag name="li" components={components} parentName="ol">{`Get data: We’ll use Google’s Distance and Elevation APIs to create an array with 100 objects representing samples along our polyline`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`Draw the maps: Use the Google Maps API to draw a map and its parts according to the data it receives as input`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`Draw the data: Add the D3 library and draw an SVG chart to represent this data visually`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`Draw the infobox: When a user hovers over the chart, we show the user the Elevation and Grade at that point`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`Draw the blip: Based on where the user hovers, we need to draw a blip (almost a marker, but not quite) on the map at that point along the path`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`Are you excited? I am too. Let’s get started.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`1. Get Data`}</MDXTag>
      <MDXTag name="p" components={components}>{`Let’s look at the docs for the Google Maps `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://developers.google.com/maps/documentation/elevation"
        }}>{`Elevation API`}</MDXTag>{` to see what we’ll be working with.`}</MDXTag>
      <MDXTag name="p" components={components}>{`From the `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://developers.google.com/maps/documentation/elevation/intro"
        }}>{`Google Map Docs`}</MDXTag>{`:`}</MDXTag>
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`...you may request sampled elevation data along paths, allowing you to calculate elevation changes along routes.`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`This is exactly what we need to do!`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Note:`}</MDXTag>{` See the `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://developers.google.com/maps/documentation/javascript/reference/coordinates#LatLng"
        }}>{`docs on the LatLng class`}</MDXTag>{` and the `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://developers.google.com/maps/documentation/javascript/markers"
        }}>{`Marker API`}</MDXTag>{` for more info on the Google Maps objects we’ll be working with.`}</MDXTag>
      <MDXTag name="p" components={components}>{`By the end of this post you’ll be defining a custom React map component with a method called `}<MDXTag name="inlineCode" components={components} parentName="p">{`getElevationAlongPath`}</MDXTag>{` that does exactly this.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Let’s skim this code so you have an idea where we’re headed, then we’ll fool around with some maps.`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-jsx"
        }}>{`import React from "react"

// Calculates beeline distance between 2 points along geodesic surface:
const calcDistance = (
  { lat: fromLat, lng: fronLng },
  { lat: toLat, lng: toLng }
) => {
  // Assumes google is available on the window object
  // (we’ll talk about how to handle this later):
  return window.google.maps.geometry.spherical.computeDistanceBetween(
    new window.google.maps.LatLng(fromLat, fromLng),
    new window.google.maps.LatLng(toLat, toLng)
  )
}

export default class ChartWrapper extends React.Component {
  state = {
    distances: [],
    elevations: [],
    markers: []
  }

  // componentDidMount() { ... }
  // componentDidUpdate(prevProps, prevState) { ... }

  // Returns a bunch of elevation samples along the path we provide it:
  getElevationsAlongPath = () => {
    // Check if we have enough markers to make a path (2+):
    if (this.state.markers.length > 1) {
      const elevator = new window.google.maps.ElevationService()
      const latLngs = this.state.markers.map(marker => ({
        lat: marker.getPosition().lat(),
        lng: marker.getPosition().lng()
      }))

      // We can also use the Distance API to calculate distance btwn Markers:
      const { distances } = latLngs.reduce(
        (acc, curr, i, arr) => {
          if (i === arr.length - 1) return acc
          const distances = acc.distances.concat(
            // Use the helper function we defined above:
            calcDistance(
              { lat: curr.lat, lng: curr.lng },
              { lat: arr[i + 1].lat, lng: arr[i + 1].lng }
            )
          )
          return distances
        },
        { distances: [] }
      )

      // API request to get our elevation samples:
      elevator.getElevationAlongPath(
        {
          path: latLngs,
          samples: 100
        },
        results => {
          this.setState({
            distances,
            // We’ll probably want to massage the data shape later:
            elevations: results.map(result => result)
          })
        }
      )
    }
  }

  render() {
    /* ... */
  }
}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://github.com/Lambda-School-Labs/LabsPT1_bkwds/blob/master/client/src/components/Maps/SingleTrip/TripPanel.js#L75"
        }}>{`See the source code here!`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Let’s talk through it.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`First`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="p">{`calcDistance`}</MDXTag>{` is a helper function that takes 2 objects of shape `}<MDXTag name="inlineCode" components={components} parentName="p">{`{lat: number, lng: number}`}</MDXTag>{` and returns the distance between them in meters.`}</MDXTag>
      <MDXTag name="p" components={components}>{`However, if we have 5 markers, there will be 4 distance calculations between them. Having 2 arrays of unequal lengths complicates things, so we initialize the first distance to 0 (lines 36-42).`}</MDXTag>
      <MDXTag name="p" components={components}>{`Now we have 5 markers and 5 distances and we don’t have to do maths later.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Second`}</MDXTag>{`, in lines 49-61 we call the Elevations API, passing in the path we want to take samples from along with the number of samples we want. We ask for 100 samples because again, math is hard.`}</MDXTag>
      <MDXTag name="p" components={components}>{`When we call our method (in our case, inside `}<MDXTag name="inlineCode" components={components} parentName="p">{`componentDidUpdate`}</MDXTag>{`), our `}<MDXTag name="inlineCode" components={components} parentName="p">{`ChartWrapper`}</MDXTag>{` state will look something like this:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`{
  distances: [3080.666166039471, 2841.623465928883, /* ... */ ],
  markers: [<GoogleMapsMarkerObject>, <GoogleMapsMarkerObject>, /* ... */ ]
  elevations: [
    {
      elevation: 149.1212768554688,
      location: <GoogleMapsLatLngClass>,
      resolution: 9.543951988220215,

    },
    {
      elevation: 152.5092315673828,
      location: <GoogleMapsLatLngClass>,
      resolution: 9.543951988220215,
    },
    // 98 more elevations...
  ]
}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Success! ✨ Let’s start drawing.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`2. Drawing the Maps`}</MDXTag>
      <MDXTag name="p" components={components}>{`First we’ll render a simple map, because we need to make sure our API key is working and because it’s fun.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Basic Map with Layer`}</MDXTag>
      <MDXTag name="p" components={components}>{`Let’s start with Madrid. We can use the TransitLayer API to sketch out the arteries of the Metro de Madrid public transit system while we’re at it.`}</MDXTag>
      <MapWithMarkers type="basic" layer="transit" mapTypeId="roadmap" center={mapCenterESP} title="Metro de Madrid" theme="day" zoom={13.6} />
      <MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`Sí, tú estás luciendo bien allí, Madrid.`}</MDXTag></MDXTag>
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`The Madrid Metro is a rapid transit system serving the city of Madrid, capital of Spain. The system is the 12th longest rapid transit system in the world, with a total length of 293 km, though Madrid is approximately the fiftieth most populous metropolitan area in the world.`}</MDXTag>
        <MDXTag name="p" components={components} parentName="blockquote">{`– Wikipedia`}</MDXTag>
      </MDXTag>
      <MDXTag name="h3" components={components}>{`Bounded Map with Markers & Polyline`}</MDXTag>
      {
        /* prettier-ignore */
      }
      <MDXTag name="p" components={components}>{`Next we need to draw some markers and connect them by a path (or “polyline”, in Google-Maps-speak). We can’t use the Directions API without a Premium account, and last I checked it takes $10,000 to open one of those. So our polyline will be an approximation of a user’s trajectory rather than a shortest path algorithm.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Our team needed to support rendering this data dynamically based on user input. Dropping, dragging and deleting markers – and then updating map state – is not the focus of this article, plus besides I’m sure you already know how you would go about it. If you’re curious what we did, the `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://github.com/Lambda-School-Labs/LabsPT1_bkwds/tree/master/client/src/components/Maps"
        }}>{`Maps folder of our repo`}</MDXTag>{` contains all that logic.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Point being:`}</MDXTag>{` We’re going to keep hardcoding values for now, but we’ll switch it up to keep things lively. Let’s go to San Francisco now because I can’t afford to go in real life:`}</MDXTag>
      <MapWithMarkers type="markersAndPolyline" center={mapCenterSF} markerPositions={markerPositionsSF} zoom={12.8} mapTypeId="roadmap" theme="comic" title="Lombard Street, SF" />
      <MDXTag name="p" components={components}>{`Great! We now have 18 markers representing a stroll down Lombard Street.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Notice how clicking “Show Markers” adjusts the map’s zoom and bounds to neatly frame the markers, while hiding them returns our map to its initial state.`}</MDXTag>
      {
        /* Here’s the method that builds up a `LatLngBounds` object for us and then adjusts our map’s “lens”<span className="asterisk"><a href="#asterisk1">\*</a></span> to contain it: */
      }
      <MDXTag name="p" components={components}>{`Here’s the method that builds up a `}<MDXTag name="inlineCode" components={components} parentName="p">{`LatLngBounds`}</MDXTag>{` object for us and then adjusts our map’s “lens” to contain it:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`class MyMapComponent extends React.Component {
  setMapBounds = (map, positions) => {
    let bounds = new window.google.maps.LatLngBounds()
    positions.forEach(p => {
      const bound = p.position
        ? { lat: p.position.lat(), lng: p.position.lng() }
        : { lat: p.lat, lng: p.lng }
      bounds.extend(bound)
    })
    map.fitBounds(bounds)
  }

  render() {
    /* ... */
  }
}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Lines 5-7:`}</MDXTag>{` If you’ve worked with the GM API for any length of time, you’ve probably gotten tired of keeping track of whether you’re dealing with an instance of the LatLng class — where latitude, for example, is accessible by calling the function `}<MDXTag name="inlineCode" components={components} parentName="p">{`LatLng.position.lat()`}</MDXTag>{`.`}</MDXTag>
      <MDXTag name="p" components={components}>{`We got tired of converting back and forth, so we wrote `}<MDXTag name="inlineCode" components={components} parentName="p">{`setMapBounds`}</MDXTag>{` to accept either type. For every coordinate object or LatLng instance we pass in, we adjust the outer bounds of our map, and finally we fit the map to those boundaries.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Drawing the polyline is as simple as:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`class MyMapComponent extends React.Component {
  polyline = null
  drawPath = (map, markers) => () => {
    const path = markers.map(marker => ({
      lat: marker.getPosition().lat(),
      lng: marker.getPosition().lng()
    }))
    const polyline = new window.google.maps.Polyline({
      path,
      strokeColor: "#000c3c",
      strokeOpacity: 1,
      strokeWeight: 2.5
    })
    this.polyline = polyline
    polyline.setMap(map)
    this.setState({ showPath: true })
  }

  render() {
    /* ... */
  }
}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Notice that we did not make this method as flexible as `}<MDXTag name="inlineCode" components={components} parentName="p">{`setMapBounds`}</MDXTag>{` w/r/t its arguments; we learned the hard way that drawing and especially `}<MDXTag name="em" components={components} parentName="p">{`redrawing`}</MDXTag>{` polylines is more complex than adjusting the map’s zoom and position. If this seems counterintuitive (it did to me at first), think of changing a map like adjusting a lens, instead of destroying and rebuilding a DOM element: nothing in the environment has actually changed, just your window into the state of the world has shifted.`}</MDXTag>
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`The map is not the territory.`}</MDXTag>
        <MDXTag name="p" components={components} parentName="blockquote">{`Alfred Korzybski`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Line 14:`}</MDXTag>{` We add the polyline to our class for use throughout the rest of the component. We also need to keep track of it so we can remove it later when the user clicks “Hide Path” (“Hide Markers” too, otherwise we’d have a marker-less path floating around with nothing anchoring it down!).`}</MDXTag>
      <MDXTag name="p" components={components}>{`Now let’s get those elevation samples.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Map Showing Elevation at Locations`}</MDXTag>
      <MapWithMarkers type="elevationForLocations" center={mapCenterHI} markerPositions={markerPositionsHI} title="Waipio Rd – Waimea, Hawaii" theme="retro" showDeltas={true} zoom={13.7} />
      <MDXTag name="p" components={components}>{`Looks like we’re in Hawaii now. What are we doing here?`}</MDXTag>
      <img src="/static_imgs/waipio_road_street_view.jpg" alt="Waipio Valley Road - Street View" style={{
        width: "100%"
      }} />
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`[Waipio Valley Road]`}{` gains 800 vertical feet (243.84 m) in 0.6 miles (0.9 km) at a 25% average grade, with steeper grades in sections. This is a paved public road but it is open only to 4 wheel drive vehicles. It is the steepest road of its length in the United States.`}</MDXTag>
        <MDXTag name="p" components={components} parentName="blockquote">{`– Wikipedia`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`If you click through the markers you’ll see how quickly the elevation changes. We’ll probably want to start thinking about how to calculate the distance between points, too, so our users have more concrete data to work with when using our app.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Let’s take this a step further. What if we could show users the elevation delta `}<MDXTag name="em" components={components} parentName="p">{`along`}</MDXTag>{` the path they’ve drawn?`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Fetching Elevation Samples Along a Path`}</MDXTag>
      <MDXTag name="p" components={components}>{`Instead of getting the difference in elevation between each point, we can ask for `}<MDXTag name="inlineCode" components={components} parentName="p">{`n`}</MDXTag>{` number of elevation samples along a path. Requesting 100 samples along a polyline, for example, is a relatively cheap request (1 API call) for how much data we get, and our area chart will be that much more granular.`}</MDXTag>
      <MapWithMarkers type="elevationAlongPath" center={mapCenterNZ} markerPositions={markerPositionsNZ} title="Baldwin St – Dunedin, NZ" theme="retro" zoom={13.8} />
      <MDXTag name="p" components={components}>{`Click “Elevation Samples” and check your browser console to see the raw data the GM API is returning! It’ll be green, like this:`}</MDXTag>
      <img src="/static_imgs/console_results.png" alt="console results" />
      <br />
      <br />
      <MDXTag name="p" components={components}>{`Here’s how I wrote the query that I’m using in this blogpost (alternatively, see `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://github.com/Lambda-School-Labs/LabsPT1_bkwds/blob/6acb51996506f714c16479a274a047bb5d0ae708/client/src/components/Maps/SingleTrip/TripPanel.js#L89"
        }}>{`how we did it in production`}</MDXTag>{`):`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-jsx"
        }}>{`class MyComponent extends React.Component {
  getElevationAlongPath = (map, polyline) => () => {
    const { maps } = window.google
    const elevator = new maps.ElevationService()
    elevator.getElevationAlongPath(
      { path: polyline.getPath().j, samples: 100 },
      (results, status) => {
        if (status === "OK") {
          // Keep this log around so a reader has access via her console:
          console.info(
            "%c GET ELEVATION ALONG PATH SUCCESS, RESULTS:",
            "background: green; color: white;",
            results
          )
          // Strip off \`resolution\` field; we don't need it:
          const elevationSamples = results.map(({ elevation, location }) => ({
            elevation,
            location
          }))
          this.setState({ elevationSamples })
        }
      }
    )
  }

  render() {
    /* ... */
  }
}
`}</MDXTag></MDXTag>
      {
        /*
        [Elevation Chart Styles](https://github.com/Lambda-School-Labs/LabsPT1_bkwds/blob/master/client/src/styles/ElevationChart.styles.js#L82) */
      }
      <MDXTag name="p" components={components}>{`To keep this example simple, we store the elevation samples at `}<MDXTag name="inlineCode" components={components} parentName="p">{`this.state.elevationSamples`}</MDXTag>{`, an array containing 100 objects with this shape:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`{
  elevation: Number,
  location: {
    lat: () => Number,
    lng: () => Number,
  }
  resolution: Number
}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`If you highlight the `}<MDXTag name="inlineCode" components={components} parentName="p">{`MapWithMarkers`}</MDXTag>{` component using React DevTools, you can access the component in your console by asking for `}<MDXTag name="inlineCode" components={components} parentName="p">{`$r`}</MDXTag>{`, a new global React DevTools has made available to you.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="inlineCode" components={components} parentName="p">{`$r`}</MDXTag>{` is an essential tool for debugging React applications. Here we use it to look at our data exactly as it exists on state. Here it is in action:`}</MDXTag>
      <iframe className="youtube-video" width="720" height="480" src="https://www.youtube.com/embed/oX3A2gPxv9I" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
      <MDXTag name="p" components={components}>{`Here’s what I did to access the elevation and created a latLng object from the object at the first index:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`> es = $r.state.elevationSamples
// => (100) [{...}, {...}, {...}, {...}, ...]
> es.elevation
// => 35.52785110473633
> { lat: es[0].location.lat(), lng: es[0].location.lng() }
// => { lat: -45.84831, lng: 170.53305999999998 }
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Great. Put those elevations somewhere you won’t lose them – local component state is probably okay if you’re not expecting the values to change much.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Great! On to the next one.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`3. Drawing the data`}</MDXTag>
      <MDXTag name="p" components={components}>{`For browser performance (and my own sanity), I broke the D3 portion out into a separate post.`}</MDXTag>
      <MDXTag name="h4" components={components}><MDXTag name="a" components={components} parentName="h4" props={{
          "href": "/posts/2019-02-16-draw-an-interactive-elevation-chart-with-d3-and-react"
        }}>{`Read all the juicy D3 deets here!`}</MDXTag></MDXTag>
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`... In that Empire, the Art of Cartography attained such Perfection that the map of a single Province occupied the entirety of a City, and the map of the Empire, the entirety of a Province. In time, those Unconscionable Maps no longer satisfied, and the Cartographers Guilds struck a Map of the Empire whose size was that of the Empire, and which coincided point for point with it. The following Generations, who were not so fond of the Study of Cartography as their Forebears had been, saw that that vast map was Useless, and not without some Pitilessness was it, that they delivered it up to the Inclemencies of Sun and Winters. In the Deserts of the West, still today, there are Tattered Ruins of that Map, inhabited by Animals and Beggars; in all the Land there is no other Relic of the Disciplines of Geography.`}</MDXTag>
        <MDXTag name="p" components={components} parentName="blockquote"><MDXTag name="a" components={components} parentName="p" props={{
            "href": "https://kwarc.info/teaching/TDM/Borges.pdf"
          }}>{`– Borges, “On Exactitude in Science”`}</MDXTag></MDXTag>
      </MDXTag>
      {
        /*
        <React.Fragment>
         <h5 id="asterisk1" className="asterisk-body">
           “Big” thought of the day: Even though client-side map services are often
           referred to as “canvases”, the user’s interaction with the canvas is more or
           less limited to the bounds of the viewport and the dimensions of the viewbox
           that comprise her interface. In my eyes this muddles the abstraction that a
           map provides, especially given the artist-as-creator connotations a word
           like “canvas” evokes; <i>lens</i> might be a more appropriate metaphor.
           Certainly that the dynamic of our relationship with mapping software could
           be pessimistically summarized as a sequence of Ouija board manipulations
           that either translate or dilate our aperture onto a model that we drew up to
           compensate for the limitations of the human nervous system ought to give us
           pause. Maybe someday mapping softwae will be studied not for its direct
           applications to the physical world around us, but for its wider
           anthropological significance, as artifact.
         </h5>
        </React.Fragment>
        */
      }
      <hr />
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Note:`}</MDXTag>{` This is part 1 of a 3-part series about using Google Maps and D3 to create an elevation graph that responds dynamically with user interaction. Here are all 3 posts:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "/posts/2019-02-11-draw-an-interactive-elevation-chart-with-d3-and-react"
          }}>{`Part 1: Google Maps`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "/posts/2019-02-16-draw-an-interactive-elevation-chart-with-d3-and-react"
          }}>{`Part 2: D3 for Data Visualization`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "/posts/2019-03-27-draw-an-interactive-elevation-chart-with-d3-and-react-pt-3"
          }}>{`Part 3: Creating User Interaction`}</MDXTag></MDXTag>
      </MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {
  "path": "/2019-02-11-draw-an-interactive-elevation-chart-with-d3-and-react",
  "date": "2019-02-11",
  "title": "Draw an Interactive Elevation Chart with D3 & React, Part 1",
  "image": "2019-02-11-draw-an-interactive-elevation-chart-with-d3-and-react.gif",
  "sharpImage": false,
  "imgOverlay": "linear-gradient(to bottom,rgb(0,0,0,0.4),rgb(67,67,67,0.1))",
  "tags": ["react", "d3", "javascript", "google maps", "data-visualization"],
  "excerpt": "Part 1: Data visualization is too fun. My team made an app that consumes a user’s trip data and uses it to draw cool stuff. Here’s how we used React, D3 and Google Maps to do it.",
  "published": true
};
      